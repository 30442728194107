/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap';

html {
  scroll-behavior: smooth;
}

button,
h1,
h2,
h3,
h4,
p,
ion-title,
ion-label,
ion-input,
ion-select,
ion-option,
ion-datetime,
ion-textarea,
div {
  font-family: 'Roboto' !important;
}

@font-face {
  font-family: 'Europa-Bold';
  src: url('assets/fonts/Europa-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Europa-Light';
  src: url('assets/fonts/Europa-Light.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Europa-Regular';
  src: url('assets/fonts/Europa-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Regular';
  src: url('assets/fonts/Outfit-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-SemiBold';
  src: url('assets/fonts/Outfit-SemiBold.otf');
  font-weight: normal;
  font-style: normal;
}

.Europa-Bold {
  font-family: Europa-Bold !important;
}

//   .Europa-Light{
//       font-family: Europa-Light !important;
//   }
//   .Europa-Regular{
//       font-family: Europa-Regular !important;
//   }

.header-bg-color {
  // background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(158,201,93,1)), color-stop(100%, rgba(83,160,44,1))); /* safari4+,chrome */
  // background: -webkit-linear-gradient(0deg, rgba(158,201,93,1) 0%, rgba(83,160,44,1) 100%); /* safari5.1+,chrome10+ */
  background-color: #FF8C00;
  // background: -moz-linear-gradient(358deg, rgb(255, 107, 8) 0%, rgba(255,241,41,1) 100%); /* ff3.6+ */
  //     background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,107,8,1)), color-stop(100%, rgba(255,241,41,1))); /* safari4+,chrome */
}

.header-text-color {
  color: white;
}

.header-font-size {
  font-size: 16px;
}

.btn-text-color {
  color: white;
}

.btn-bg-color {
  // background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(158,201,93,1)), color-stop(100%, rgba(83,160,44,1))); /* safari4+,chrome */
  // background: -webkit-linear-gradient(0deg, rgba(158,201,93,1) 0%, rgba(83,160,44,1) 100%); /* safari5.1+,chrome10+ */

  // background: -moz-linear-gradient(358deg, rgb(255, 107, 8) 0%, rgba(255,241,41,1) 100%); /* ff3.6+ */
  // background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,107,8,1)), color-stop(100%, rgba(255,241,41,1))); /* safari4+,chrome */
  background-color: #FF8C00;
}

.btn-font-size {
  font-size: 15px;
}

.app-bg-color {
  background-color: #f8f8f8;
}

.card-bg-color {
  background-color: white;
}

.level-text-color {
  color: #132744;
}

.level-font-size {
  font-size: 17px;
  // font-family: Europa-Regular;
  font-family: Outfit-Regular;
}

.input-text-color {
  color: #132744;
  // font-family: Europa-Regular;
  font-family: Outfit-Regular;
}

.input-font-size {
  font-size: 17px;
}

////////////////////////Header CSS code//////////////////////
.header-main-div {
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.header-left-div {
  padding-left: 10px;

  .event-icon {
    width: 22px;
    height: 27px;
    ;
  }
}

.header-mid-div {
  .toolbar-background-md {
    background-color: white !important;
  }

  // font-family: Europa-Regular;
  font-family: Outfit-Regular;
  padding-right: 10px;
  font-size: 24px;
  color:#3F4F68;
}

.header-right-div {
  padding-right: 10px;
  padding-top: 5px;
}

.header {
  text-align: center;
}

.header p {
  margin: 11px;

  float: right;
}

.back-icon {
  width: 18px;
  height: 20px;
}

.toolbar-background-md {
  background-color: white !important;
}

.toolbar-background-ios {
  background-color: white !important;
}

.text-capital-lower {
  text-transform: lowercase;
}

.text-capital-auto {
  text-transform: lowercase;
}

.text-capital-auto {
  text-transform: capitalize;
}

.back-icon-logo {
  width: 20px;
  height: 25px;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

//Custom Loader CSS
.custom-loader {
  --background: transparent;

  ion-backdrop {
    background-color: rgba(255, 255, 255, 0.591);
    opacity: .9 !important;
  }

  .loading-wrapper {
    -webkit-animation: ld-vortex-out 2s ease-out infinite;
    animation: ld-vortex-out 2s ease-out infinite;
    animation-timing-function: cubic-bezier(0.05, 0, 3, 0.05);

    background-image: url("./assets/imgs/spot-transparent.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    min-width: 90px;
    min-height: 90px;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

@keyframes ld-vortex-out {
  0% {
    -webkit-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
  }

  60% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 0;
  }
}


@-webkit-keyframes ld-vortex-out {
  0% {
    -webkit-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
  }

  60% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 0;
  }
}



.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}